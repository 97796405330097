import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { isMobile } from "react-device-detect";
import SmoothScroll from "smooth-scroll";

import Contact from "./Contact"; // Adjust the path as necessary
import Home from "./Home";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [loading, setLoading] = useState(true);
  //Loader Animation
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      document.querySelectorAll(".pointer").forEach((pointer, index) => {
        setTimeout(() => {
          pointer.style.left = `${e.pageX}px`;
          pointer.style.top = `${e.pageY}px`;
        }, index * 50);
      });
    };

    if (!isMobile) {
      window.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (!isMobile) {
        window.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <img
            src="img/icon.svg"
            className="logo-loader"
            alt="ICB Verse Loading..."
          />
        </div>
      ) : (
        <div>
          <div className="trail">
            <div className="pointer pointer1"></div>
            <div className="pointer pointer2"></div>
            <div className="pointer pointer3"></div>
            <div className="pointer pointer4"></div>
            <div className="pointer pointer5"></div>
          </div>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </Router>
        </div>
      )}
    </>
  );
};

export default App;
